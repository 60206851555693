import React, { useMemo, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaste } from '@fortawesome/free-solid-svg-icons';
import Table from './components/Table';

function UrlDownloader() {
  const [videoURL, setVideoURL] = useState('');
  const [activeTab, setActiveTab] = useState('video');
  const [videoDetails, setVideoDetails] = useState(null);
  const [audioFormats, setAudioFormats] = useState(null)
  const [formats, setFormats] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');

  const fetchFormats = async () => {
    if (!isValidUrl(videoURL)) {
      setError('Please enter a vaild video URL');
      return;
    }
    setFormats([]);
    setVideoDetails({});
    setIsFetching(true);
    try {
      const { data: { title, thumbnail, video_formats, audio_formats } } = await axios.post(`${process.env.REACT_APP_BASE_URL_PYTHON}/url/info`, { url: videoURL });
      setVideoDetails({ title, thumbnail });
      setFormats(video_formats);
      setAudioFormats(audio_formats)
      setVideoURL('')
      setError('');
    } catch (error) {
      setError('Failed to fetch the video. Please try again!');
    }
    setIsFetching(false);
  };

  const handleUrlInput = (e) => {
    setVideoURL(e.target.value);
    setError('');
  };




  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  }

  const avaliableFormat = useMemo(() => activeTab === 'video' ? formats : audioFormats, [activeTab, formats, audioFormats])

  return (
    <div className="p-4">
      <div className="mb-4 relative">
        <input
          type="url"
          placeholder={`Enter YouTube, Facebook, Twitter and Instagram Video URL`}
          value={videoURL}
          onChange={handleUrlInput}
          className="w-full p-2 pr-10 border border-gray-300 rounded mb-2"
        />
        <FontAwesomeIcon
          icon={faPaste}
          className="absolute right-3 top-3 text-gray-500 cursor-pointer"
          onClick={() => navigator.clipboard.readText().then(text => setVideoURL(text))}
        />
        <button
          onClick={fetchFormats}
          disabled={isFetching}
          className={`w-full p-2 rounded ${isFetching ? 'bg-gray-400' : 'bg-blue-500 text-white hover:bg-blue-600'} transition duration-200`}
        >
          {isFetching ? 'Fetching...' : 'Submit'}
        </button>
        {error && <div className="text-red-500 mt-2">{error}</div>}
      </div>

      <>
        {videoDetails !==null &&
        <>
          <div className="flex justify-center mb-4">
            <button
              onClick={() => setActiveTab('video')}
              disabled={activeTab === 'video'}
              className={`px-4 py-2 mx-2 rounded ${activeTab === 'video' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} transition duration-200`}
            >
              Video
            </button>
            <button
              onClick={() => setActiveTab('mp3')}
              disabled={activeTab === 'mp3'}
              className={`px-4 py-2 mx-2 rounded ${activeTab === 'mp3' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} transition duration-200`}
            >
              MP3
            </button>
          </div>
        
        <div className="mb-4">
          <h3 className="text-xl font-bold mb-2">{videoDetails.title}</h3>
          {videoDetails?.thumbnail && <img src={videoDetails?.thumbnail} alt={videoDetails.title} className="max-w-full h-auto rounded shadow-md" />}
        </div>
        </>
}
        {avaliableFormat && !!avaliableFormat.length && (
          <Table formats={avaliableFormat} />
        )}
      </>

    </div>
  );
}

export default UrlDownloader;
