import React from 'react';
import UrlDownloader from './UrlDownloader';
import VideoDownloader from './VideoDownloader';
import TestVideoD from './TestVideoD';

function App() {

  return (
    <div className="flex flex-col min-h-screen items-center justify-center bg-gray-100">
      <header className="w-full bg-blue-600 py-6 shadow-md">
        <div className="container mx-auto text-center text-white">
          <h1 className="text-3xl font-bold">Media Downloader</h1>
          <p className="text-lg">Download your favorite media from YouTube, Facebook, Twitter, Instagram, and TikTok</p>
        </div>
      </header>
      <main className="flex-grow bg-white p-8 rounded shadow-md w-full max-w-md mt-8">
        <div>
          {/* <TestVideoD/> */}
          <UrlDownloader />
          {/* <VideoDownloader/> */}
        </div>
      </main>
      <footer className="w-full bg-blue-600 py-4 mt-8">
        <div className="container mx-auto text-center text-white">
          <p>&copy; {new Date().getFullYear()} Media Downloader. All rights reserved.</p>
          <p className="text-sm">Developed by Your Name</p>
          <div className="flex justify-center mt-2">
            <a href="#" className="mx-2 hover:underline">Privacy Policy</a>
            <a href="#" className="mx-2 hover:underline">Terms of Service</a>
            <a href="#" className="mx-2 hover:underline">Contact</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
