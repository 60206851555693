import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import React, { useState } from 'react'

export default function Table({formats}) {
    const [loading, setLoading] = useState({});
    const handleDownload = async (url, fileName,format_id,original_url) => {
        if(isValidTiktokUrl(original_url)){
            
            await downloadTikTokVideo(fileName,format_id,original_url)
            return
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

   const isValidTiktokUrl = (url,) => {
        const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/@[\w.-]+\/video\/\d+(\?.*)?$|^https:\/\/v\d+-webapp-prime\.tiktok\.com\/video\/tos\/[\w\/?.=&%-]+$/;
        return tiktokRegex.test(url);
      };

      const downloadTikTokVideo = async (fileName,format_id,original_url) => {
        try {
            setLoading(prev => ({ ...prev, [format_id]: true }));
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL_PYTHON}/download_video`,{ url:original_url,format_id },{ responseType: 'blob' } ); 
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();


        } catch (err) {
            console.log(err);
          //setError('Something went wrong!',err);
        } finally {
            setLoading(prev => ({ ...prev, [format_id]: false }));  // Reset loading state
          }
      };


    return (
        <> 
        <div>
            <table className="w-full table-auto border-collapse">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="p-2 border border-gray-300">Resolution</th>
                        <th className="p-2 border border-gray-300">File Size</th>
                        <th className="p-2 border border-gray-300">Download</th>
                    </tr>
                </thead>
                <tbody>
                    {React.Children.toArray(formats.map((format) => (
                        <tr key={format.itag} className="hover:bg-gray-100 transition duration-200">
                            <td className="p-2 border border-gray-300">
                                {format.resolution}
                                <span className="ml-2">
                                    {format.has_sound ? (
                                        <FontAwesomeIcon icon={faVolumeUp} className="text-green-500" />
                                    ) : (
                                        <FontAwesomeIcon icon={faVolumeMute} className="text-red-500" />
                                    )}
                                </span>
                            </td>
                            <td className="p-2 border border-gray-300">{format.filesize ?  `${format.filesize} MB` :  "Unknown"} </td>
                            <td className="p-2 border border-gray-300">
                            <button
                                    onClick={() => handleDownload(format.url, `video_${format.resolution}.mp4`,format.format_id,format.original_url )}
                                    className="text-blue-500 hover:underline"
                                    disabled={loading[format.format_id]}
                                >
                                   {loading[format.format_id] ? 'Loading...' : 'Download'}
                                </button>
                                {/* <a
                                     href={`${format.url}`}
                                    // target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 hover:underline"
                                    download
                                >
                                    Download
                                </a> */}
                            </td>
                        </tr>
                    )))}
                </tbody>
            </table>
        </div>
        
        </>
    )
}
